export default class BasisVideo extends HTMLElement {
	/**
	* constructor
	* @returns {void}
	*/
	constructor() {
		super();
		this.video = null;
		this.videoContainer = null;
		this.playButton = null;
		this.skipButton = null;
		this.isDisconnected = false;

		this._initializeModule = this._initializeModule.bind(this);
		this._startVideo = this._startVideo.bind(this);
		this._stopVideo = this._stopVideo.bind(this);
		this._handleScrollDependentVideo = this._handleScrollDependentVideo.bind(this);
		this._handleVideoEnded = this._handleVideoEnded.bind(this);
	}

	/**
	* connectedCallback - callback for adding element to dom/shadow dom
	* @returns {void}
	*/
	connectedCallback() {
		this._initializeModule();
	}

	/**
	* @returns {void}
	*/
	disconnectedCallback() {
		this._removeEvents();
		this.isDisconnected = true; // double safety net
	}

	/**
	* _initializeModule - inits module, bind events
	* @returns {void}
	*/
	_initializeModule() {
		this._initializeElements();

		if (!this.video) {
			return;
		}

		this._addEvents();

		if (this._isAutoplayVideo() && this._videoContainerIsMostlyInViewport()) {
			this._startVideo();
		}
	}

	_initializeElements() {
		if (!this.video) {
			this.video = this.querySelector('video');
			this.videoContainer = this.querySelector('.nm-basis-video-player');
			this.playButton = this.querySelector('.nm-basis-video-play-button');
			this.skipButton = this.querySelector('.nm-basis-video-skip-button');
		}
	}

	/**
	* _bindEvents - event binding
	* @returns {void}
	*/
	_addEvents() {
		// video events
		this.video.addEventListener('ended', this._handleVideoEnded);

		// play via button
		if (this.playButton) {
			this.playButton.addEventListener('mousedown', this._startVideo);
			this.playButton.addEventListener('touchstart', this._startVideo);
		}

		// skip by button
		if (this.skipButton) {
			this.skipButton.addEventListener('mousedown', this._stopVideo);
			this.skipButton.addEventListener('touchstart', this._stopVideo);
		}

		window.addEventListener('scroll', this._handleScrollDependentVideo);
	}

	/**
	* _unbindEvents - remove event binding
	* @returns {void}
	*/
	_removeEvents() {
		// viewport events
		window.removeEventListener('scroll', this._handleScrollDependentVideo);

		if (this.video) {
			this.video.pause();

			this.video.removeEventListener('ended', this._handleVideoEnded);
		}

		// play via button
		if (this.playButton) {
			this.playButton.removeEventListener('mousedown', this._startVideo);
			this.playButton.removeEventListener('touchstart', this._startVideo);
		}

		// skip by button
		if (this.skipButton) {
			this.skipButton.removeEventListener('mousedown', this._stopVideo);
			this.skipButton.removeEventListener('touchstart', this._stopVideo);
		}
	}

	/**
	* _videoContainerIsVisible
	* @returns {boolean} - is video container visible or not
	*/
	_videoContainerIsVisible() {
		return (this._getPercentageOfVisibleVideoContainerHeight() > 0);
	}

	/**
	* _videoContainerInViewport more then 50%
	* @returns {boolean} - is video container is in viewport 50% or less
	*/
	_videoContainerIsMostlyInViewport() {
		return (this._getPercentageOfVisibleVideoContainerHeight() >= 50);
	}

	/**
	* _getPercentageOfVisibleVideoContainerHeight - calculate how many percent of the height of video container is visible
	* @returns {number} - percentage height
	*/
	_getPercentageOfVisibleVideoContainerHeight() {
		let rect = this.videoContainer.getBoundingClientRect(),
			pixelVisible = window.innerHeight - rect.top;
		if (rect.top < 0) {
			pixelVisible = rect.bottom;
		}
		if (pixelVisible <= 0) {
			return 0;
		}
		if (pixelVisible >= this.videoContainer.clientHeight) {
			return 100;
		}
		return (pixelVisible / this.videoContainer.clientHeight) * 100;
	}

	/**
	 * _isAutoplayVideo - verifying whether then video should be played in the autoplaymodus or not
	 * @returns {boolean} - is autoplay or not
	 */
	_isAutoplayVideo() {
		return (this.videoContainer.hasAttribute('data-autoplay'));
	}

	/**
	 * private function handles the playmode as in autoplay/ playbutton
	 * @returns {void}
	 */
	async _startVideo() {
		if (this.isDisconnected) {
			return;
		}

		this.video.removeEventListener('canplaythrough', this._startVideo);

		try {
			await this.video.play();
			this.videoContainer.classList.add('nm-basis-video-playing');
		}
		catch (error) {
			console.log('video could not be played because: ', error);
			this.video.muted = true;
			this.video.addEventListener('canplaythrough', this._startVideo);
		}
	}

	/**
	 * private function handles the skipping of the video
	 * @returns {void}
	 */
	_stopVideo() {
		if (this.video) {
			this.video.pause();
			this.video.currentTime = 0;
			this._handleVideoEnded();
		}
	}

	/**
	 * private function handling the video ended state
	 * @returns {void}
	 */
	_handleVideoEnded() {
		this.videoContainer.classList.remove('nm-basis-video-playing');
		if (this._isAutoplayVideo()) {
			this.videoContainer.classList.add('nm-basis-video-autoplay-played');
		}
	}

	/**
	 * private function handling the videostopping and playing depending of position within viewport
	 * @returns {void}
	 */
	_handleScrollDependentVideo() {
		if (this._videoContainerIsMostlyInViewport() && this._isAutoplayVideo()) {
			if (!this.videoContainer.classList.contains('nm-basis-video-autoplay-played')) {
				this._startVideo();
			}
		}
		else {
			this.videoContainer.classList.remove('nm-basis-video-autoplay-played');

			if (this.videoContainer.classList.contains('nm-basis-video-playing')) {
				this._stopVideo();
			}
		}
	}
}

if (window.customElements.get('audi-basis-video') === undefined) {
	window.customElements.define('audi-basis-video', BasisVideo);
}
