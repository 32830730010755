import {dom, fetch} from 'core-utils';
import {appEvents, signal} from 'core-application';

/**
 * Object holding all private functions
 */
let __ = {};

/**
 * some selector variables
 */
let _selectorModul = '.nm-module-scroll-technology',
	_selectorVideo = '.nm-scroll-technology-video-player',
	_selectorVideoContainer = '.nm-scroll-technology-video-container',
	_selectorHighlightText1 = '.nm-scroll-technology-highlighttext-one',
	_selectorHighlightText2 = '.nm-scroll-technology-highlighttext-two',
	_selectorHighlightTextWrapper = '.nm-scroll-technology-textoverlay-wrapper',
	_selectorTextblockHidden = '.nm-scroll-technology-textblock.nm-scroll-technology-textblock-hidden',
	_selectorContentWrapper = '.nm-content',
	_selectorNavigation = '.nm-navigation-main-wrap';

/**
 * some module variables
 */
let _moduleData,
	_eventBus,
	_contentWrapper,
	_topOffset = 0,
	_defaultPlayBackrate = 5,
	_hiddenTextblocks = null,
	_minimalFpsForAnimation = 15;

/** private functions */
/**
 * private initialize method
 * @returns {undefined}
 */
__.init = function () {
	const anyModule = dom.getElement(_selectorModul);

	if (dom.isElement(anyModule))
	{
		__.handleTextBlockShow();
		__.addEvents();
		__.loadVideos();
	}
};

/**
 * @description private function adding events
 * @returns {undefined}
 */
__.addEvents = function () {
	// wether a shown video would perform good
	_eventBus.addListener('video_performance', __.initializeVideos);
	window.addEventListener("scroll", __.textBlockShowListener);
	window.addEventListener('resize', __.handleSetAllHighlightHeightListener);
};

/**
 * @description private function removing events
 * @returns {undefined}
 */
__.removeEvents = function () {
	_eventBus.removeListener('video_performance', __.initializeVideos);
	window.removeEventListener("scroll", __.textBlockShowListener);
	window.removeEventListener('resize', __.handleSetAllHighlightHeightListener);
	if (__.animateAlternativeListener) {
		window.removeEventListener('scroll', __.animateAlternativeListener);
	}

	if (__.animateModuleListener) {
		window.removeEventListener('scroll', __.animateModuleListener);
	}
};

/** getter/setter */
/**
 * @description private function get the video of the modul
 * @param {DOMElement} module - the module of the video
 * @returns {HTMLVideoElement} video
 */
__.getVideoByModule = function (module) {
	return dom.getElement(_selectorVideo, module);
};

/**
 * @description private function get the moduleid by some Childelement
 * @param {DOMElement} element - checildelement of the module
 * @returns {DOMElement} moduleId
 */
__.getModuleIdByElement = function (element) {
	const module = dom.closest(element, _selectorModul);

	if (dom.isElement(module) === false)
	{
		return null;
	}
	return module.getAttribute('id');
};

/**
 * private function returning the value for the minimal fps
 * @param {DOMElement} module - one scroll technology module
 * @returns {Number} fps
 */
__.getMinimalFramesPerSecond = function (module) {
	const minfps = dom.getDataAttribute(module, 'minfps');
	if (!!minfps && minfps > 0)
	{
		return minfps;
	}
	return _minimalFpsForAnimation;
};

/**
 * @description private function get all number elements still hidden
 * @returns {Array} hiddenTextblocks
 */
__.getHiddenTextblocks = function () {
	if (!_hiddenTextblocks)
	{
		_hiddenTextblocks = dom.getElementsArray(_selectorTextblockHidden);
	}
	return _hiddenTextblocks;
};

/**
 * @description private function get data of the module
 * @param {String} moduleId - the id of the module
 * @param {String} key - the key of the data
 * @returns {String} data value
 */
__.getModuleData = function (moduleId, key) {
	if (!_moduleData || !_moduleData[moduleId] || !_moduleData[moduleId][key])
	{
		return null;
	}
	return _moduleData[moduleId][key];
};

/**
 * @description private function set data of the module
 * @param {String} moduleId - the id of the module
 * @param {String} key - the key of the data
 * @param {String} value - data value
 * @returns {undefined}
 */
__.setModuleData = function (moduleId, key, value) {
	if (!_moduleData)
	{
		_moduleData = {};
	}
	if (!_moduleData[moduleId])
	{
		_moduleData[moduleId] = {};
	}
	_moduleData[moduleId][key] = value;
};

/**
 * @description returns the current position of the video in view
 * pos = -1 -> video-top above viewport-top or below startposition
 * @param {DOMElement} module - the module of the video
 * @param {String} moduleId - the id of the module
 * @param {DOMElement} videoContainer - the container of video
 * @return {number} position of video
 */
__.getCurrentPositionInView = function (module, moduleId, videoContainer) {
	const rect = module.getBoundingClientRect(),
		top = rect.top + __.getModuleData(moduleId, 'videoOffsetTop');

	if (top === 0 && _topOffset === 0 && rect.bottom === 0 && videoContainer.clientHeight === 0)
	{
		return -1;
	}
	else if (top <= _topOffset && rect.bottom >= videoContainer.clientHeight + _topOffset)
	{
		return -(top - _topOffset);
	}

	return -1;
};

/**
 * @description returns the current position of the video in view
 * pos = -1  -> video-top above viewport-top or below startposition
 * @param {String} moduleId - the id of the module
 * @param {DOMElement} video - the video
 * @return {number} position of video
 */
__.getCurrentVideoPositionInView = function (moduleId, video) {
	const rect = video.getBoundingClientRect(),
		startPos = __.getModuleData(moduleId, 'startPosition');

	if (rect.top === 0 && startPos === 0)
	{
		return -1;
	}
	else if (rect.top >= 0 && startPos >= rect.top)
	{
		return startPos - rect.top;
	}
	return -1;
};

/**
 * @description private function setting the initial Data
 * @param {String} moduleId - the id of the module
 * @param {DOMElement} video - the video
 * @returns {undefined}
 */
__.setInitialModuleData = function (moduleId, video) {
	let pixelDuration;
	const module = dom.closest(video, _selectorModul);

	// the amount of pixel to be scrollt in order to play the complete video
	pixelDuration = window.innerHeight / _defaultPlayBackrate * video.duration;
	__.setModuleData(moduleId, 'pixelDuration', pixelDuration);
	__.setModuleData(moduleId, 'pixelDurationMapping', video.duration / pixelDuration);
	__.setModuleData(moduleId, 'videoOffsetTop', video.getBoundingClientRect().top - module.getBoundingClientRect().top); // margin between top of module and top of video
};

/**
 * @description private function setting the initial Data for module alternative
 * @param {DOMElement} module - the module of the video
 * @param {String} moduleId - the id of the module
 * @param {DOMElement} video - the video
 * @returns {undefined}
 */
__.setInitialAlternativeModuleData = function (module, moduleId, video) {
	__.setModuleData(moduleId, 'startPosition', window.innerHeight - (video.clientHeight * 0.75));
	__.setInitializeHighlightTexts(module, moduleId, video, true);
};

/**
 * @description private function setting the initial Data for the highlights
 * @param {DOMElement} module - the module of the video
 * @param {String} moduleId - the id of the module
 * @param {DOMElement} video - the video of the module
 * @param {Boolean} isAlternative - whether the alternative is loaded or not
 * @returns {undefined}
 */
__.setInitializeHighlightTexts = function (module, moduleId, video, isAlternative) {
	__.setModuleData(moduleId, 'text1', dom.getElement(_selectorHighlightText1, module));
	__.setModuleData(moduleId, 'text2', dom.getElement(_selectorHighlightText2, module));
	__.setModuleData(moduleId, 'videoOffsetTop', video.getBoundingClientRect().top - module.getBoundingClientRect().top);

	if (isAlternative)
	{
		__.setModuleData(moduleId, 'textOverlayStartPosition', __.getModuleData(moduleId, 'startPosition') * 0.45);
		__.setModuleData(moduleId, 'textOverlayEndPosition', __.getModuleData(moduleId, 'startPosition') * 0.55);
		__.setModuleData(moduleId, 'textOverlayPixelMapping', 1 / (__.getModuleData(moduleId, 'textOverlayEndPosition') - __.getModuleData(moduleId, 'textOverlayStartPosition')));
	} else
	{
		__.setModuleData(moduleId, 'textOverlayStartPosition', __.getModuleData(moduleId, 'videoOffsetTop') * 0.45);
		__.setModuleData(moduleId, 'textOverlayEndPosition', __.getModuleData(moduleId, 'videoOffsetTop') * 0.55);
		__.setModuleData(moduleId, 'textOverlayPixelMapping', 1 / (__.getModuleData(moduleId, 'textOverlayEndPosition') - __.getModuleData(moduleId, 'textOverlayStartPosition')));
	}
	__.animateHightTexts(moduleId, -video.getBoundingClientRect().top);
	__.setHighlightHeight(moduleId);
};

/**
 * private function setting the highlighttexts up
 * @param {Boolean} onResize - whether the function was calle while rezize or not
 * @return {undefined}
 */
__.handleSetAllHighlightHeight = function () {
	let key, moduleId,
		modules = dom.getElementsArray(_selectorModul);
	for (key in modules)
	{
		if (modules.hasOwnProperty(key))
		{
			moduleId = modules[key].getAttribute('id');
			__.setHighlightHeight(moduleId);
		}
	}
};

/**
 * private function setting the height of the highlight-wrapper
 * @param {String} moduleId - the id of the module
 * @return {undefined}
 */
__.setHighlightHeight = function (moduleId) {
	let highlightWrapper,
		secondHighlight = __.getModuleData(moduleId, 'text2'),
		firstHighlight = __.getModuleData(moduleId, 'text1');

	if (dom.isElement(firstHighlight))
	{
		highlightWrapper = dom.closest(firstHighlight, _selectorHighlightTextWrapper);
		highlightWrapper.style.minHeight = "0px";
	}

	if (dom.isElement(secondHighlight) && window.innerWidth < 768)
	{
		highlightWrapper = dom.closest(secondHighlight, _selectorHighlightTextWrapper);
		highlightWrapper.style.minHeight = secondHighlight.clientHeight + "px";
	}
};

// /** eventhandler */
/**
 * @description private function handling the visibility of the textblocks
 * @returns {undefined}
 */
__.handleTextBlockShow = function () {
	let key, textblocks = __.getHiddenTextblocks();
	if (textblocks.length < 1)
	{
		window.removeEventListener("scroll", __.textBlockShowListener);
	} else
	{
		for (key in textblocks)
		{
			if (!textblocks.hasOwnProperty(key))
			{
				continue;
			}
			if (dom.getVisibleVerticalPercentageInViewport(textblocks[key]) >= 25)
			{
				textblocks[key].classList.remove("nm-scroll-technology-textblock-hidden");
			}
		}
		_hiddenTextblocks = dom.getElementsArray(_selectorTextblockHidden);
	}
};

/**
 * @description private function handling the video if it is ready to be played
 * @param {Event} event - video canplaythroughevent
 * @returns {undefined}
 */
__.handleVideoCanPlayThrough = function (event) {
	const video = event.target;
	const videoContainer = dom.closest(video, _selectorVideoContainer);
	const moduleId = __.getModuleIdByElement(video);
	const module = dom.closest(video, _selectorModul);

	video.removeEventListener("canplaythrough", __.handleVideoCanPlayThrough);
	video.pause();
	video.currentTime = 0;
	video.muted = false;
	video.autoplay = false;

	__.setInitialModuleData(moduleId, video);
	__.setupInitialModuleState(module, moduleId, videoContainer);

	__.animateModuleListener = dom.throttle(__.animateModule.bind(this, module, moduleId, video, videoContainer), 15);
	window.addEventListener('scroll', __.animateModuleListener);
};

/**
 * @description private function setting initial positions/height of the modules elements
 * @param {DOMElement} module - the module of the video
 * @param {String} moduleId - the id of the module
 * @param {DOMElement} videoContainer - the container of video
 * @returns {undefined}
 */
__.setupInitialModuleState = function (module, moduleId, videoContainer) {
	if (!module) {
		return;
	}

	let rect;
	module.style.height = module.clientHeight + __.getModuleData(moduleId, 'pixelDuration') + _topOffset + "px";
	rect = module.getBoundingClientRect(); // needs to be set AFTER setting the height
	if (rect.top < -__.getModuleData(moduleId, 'videoOffsetTop') && rect.bottom > videoContainer.clientHeight && rect.bottom > videoContainer.getBoundingClientRect().bottom) {
		__.handleFollowingModulesFixed(module, moduleId, videoContainer);
	}
	else {
		__.handleFollowingModules(module, moduleId, videoContainer);
	}
};

/**
 * @description private function handle the animation of the module
 * @param {DOMElement} module -the HTML modul of the video
 * @param {String} moduleId - the id of the module
 * @param {DOMElement} video - the video
 * @returns {undefined}
 */
__.animateModule = function (module, moduleId, video, videoContainer) {
	if (!video) {
		return;
	}

	const posViewPort = __.getCurrentPositionInView(module, moduleId, videoContainer);
	if (posViewPort >= 0)
	{ // sticky
		__.animateVideo(moduleId, video, posViewPort);
		__.animateHightTexts(moduleId, posViewPort);
		if (!__.getModuleData(moduleId, 'fixed'))
		{
			__.handleFollowingModules(module, moduleId, videoContainer);
			__.handleFollowingModulesFixed(module, moduleId, videoContainer);
		}
	}
	else { // not sticky
		if (!!__.getModuleData(moduleId, 'fixed') && __.getModuleData(moduleId, 'fixed') === true)
		{
			__.handleFollowingModules(module, moduleId, videoContainer);
		}
	}
};

/**
 * @description private function handle the animation of the module
 * @param {String} moduleId - the id of the module
 * @param {DOMElement} video - the video
 * @returns {undefined}
 */
__.animateAlternative = function (moduleId, video) {
	if (!video) {
		return;
	}

	const posViewPort = __.getCurrentVideoPositionInView(moduleId, video);
	if (posViewPort >= 0)
	{
		if (video.paused && video.currentTime === 0)
		{
			const playPromise = video.play();

			if (playPromise !== undefined) {
				playPromise.catch(error => {
					console.error(error);

					if (video) {
						video.muted = true;
						video.play();
					}
				}).then(() => {
					video.play();
				});
			}
		}
		__.animateHightTexts(moduleId, posViewPort);
	} else if (video.currentTime > 0 && dom.getVisibleVerticalPercentageInViewport(video) === 0)
	{
		video.pause();
		video.currentTime = 0;
	}
};

/**
 * @description private function handle the animation of the videoSrc
 * @param {String} moduleId - the id of the module
 * @param {DOMElement} video - the video
 * @param {number} posViewPort - the position of the video in viewport
 * @returns {undefined}
 */
__.animateVideo = function (moduleId, video, posViewPort) {
	if (!video) {
		return;
	}

	video.currentTime = __.getModuleData(moduleId, 'pixelDurationMapping') * posViewPort;
};

/**
 * @description private function handling the animation of the texts
 * @param {String} moduleId - the id of the module
 * @param {number} posViewPort - the position of the video in viewport
 * @returns {undefined}
 */
__.animateHightTexts = function (moduleId, posViewPort) {
	let text1, text2,
		opacity = __.calculateHighlightTextsOpacity(__.getModuleData(moduleId, 'textOverlayPixelMapping'), posViewPort, __.getModuleData(moduleId, 'textOverlayStartPosition'));

	text2 = __.getModuleData(moduleId, 'text2');
	if (!!text2)
	{
		text2.style.opacity = opacity;
	}
	text1 = __.getModuleData(moduleId, 'text1');
	if (!!text1)
	{
		text1.style.opacity = 1 - opacity;
	}
};

/**
 * @description private function calculating the opacity of the highlight texts
 * @param {Number} pixelMapping - textOverlayPixelMapping
 * @param {Number} posViewPort - position of the video in the viewport
 * @param {Number} startPosition - startposition of the animation
 * @returns {Number} the calculated opacity
 */
__.calculateHighlightTextsOpacity = function (pixelMapping, posViewPort, startPosition) {
	let opacity = Math.round((pixelMapping * (posViewPort - startPosition)) * 10) / 10;
	if (opacity > 1)
	{
		opacity = 1;
	} else if (opacity < 0)
	{
		opacity = 0;
	}
	return opacity;
};

/**
 * @description private function handling the distance of the following modules
 * @param {DOMElement} module - the module of the video
 * @param {String} moduleId - the id of the module
 * @param {DOMElement} videoContainer - the container of the video
 * @returns {undefined}
 */
__.handleFollowingModules = function (module, moduleId, videoContainer) {
	let diff = 0,
		nextSiblings = __.getNextSiblings(module, moduleId),
		rectVideo = videoContainer.getBoundingClientRect();

	if (rectVideo.top > _topOffset)
	{
		diff = __.getModuleData(moduleId, 'pixelDuration') + _topOffset;
	}

	nextSiblings.forEach(function (sibling) {
		sibling.style.position = "relative";
		sibling.style.transform = "translateY(" + -diff + "px)";
		sibling.style.top = "0px";
	}, this);
	_contentWrapper.style.paddingBottom = "0px";
	__.setModuleData(moduleId, 'fixed', null);
};

/**
 * @description private function handling the fixing of the following modules
 * @param {DOMElement} module - the module of the video
 * @param {String} moduleId - the id of the module
 * @param {DOMElement} videoContainer - the container of the video
 * @returns {undefined}
 */
__.handleFollowingModulesFixed = function (module, moduleId, videoContainer) {
	let nextSiblings = __.getNextSiblings(module, moduleId),
		top = videoContainer.clientHeight + _topOffset;

	__.handleFollowingModules(module, moduleId, videoContainer);
	nextSiblings.forEach(function (sibling) {
		sibling.style.transform = "translateY(0px)";
		sibling.style.position = "fixed";
		sibling.style.top = top + "px";
		top += sibling.clientHeight;
	}, this);
	_contentWrapper.style.paddingBottom = top - videoContainer.clientHeight + _topOffset + "px";
	__.setModuleData(moduleId, 'fixed', true);
};

/**
 * @description private function handling the loading of the video
 * @returns {undefined}
 */
__.loadVideos = function () {
	const modules = dom.getElementsArray(_selectorModul);

	for (const key in modules)
	{
		if (modules.hasOwnProperty(key))
		{
			const module = modules[key];
			const video = __.getVideoByModule(module);
			const videoSrc = __.getVideoSrc(video);
			__.handleXMLHTTPRequest(videoSrc, video);
		}
	}
};

/**
 * @description private function returning the source of the video based on the screen-width
 * @param {HTMLVideoElement} video - the video
 * @returns {String} video source
 */
__.getVideoSrc = function (video) {
	const screenWidth = window.innerWidth;
	let source = dom.getDataAttribute(video, 'srcxl');

	if (screenWidth < 1025)
	{
		source = (dom.getDataAttribute(video, 'srcl') || source);
	}
	else if (screenWidth < 768)
	{
		source = (dom.getDataAttribute(video, 'srcm') || source);
	}
	else if (screenWidth < 415)
	{
		source = (dom.getDataAttribute(video, 'srcs') || source);
	}

	if (!source)
	{
		source = (dom.getDataAttribute(video, 'srcl') || dom.getDataAttribute(video, 'srcm') || dom.getDataAttribute(video, 'srcs'));
	}

	return source;
};

/**
 * @description private function handling the xhtmlhttprequest to get the video
 * @param {String} url - url for the video
 * @param {HTMLVideoElement} video - the video element
 * @return {undefined}
 */
__.handleXMLHTTPRequest = function (url, video) {
	fetch
		.getBlob(url, {
			method: 'get'
		}).then(function (blob) {
			video.src = URL.createObjectURL(blob);
		}).catch(function (err) {
			console.error("Error: " + err.message);
		});
};

/**
 * @description private function handling the loaded video
 * @param {DOMElement} module - the HTML modul of the video
 * @param {DOMElement} moduleId - the id of the module
 * @param {objectURL} video - the video
 * @returns {undefined}
 */
__.useVideoAlternative = function (module, moduleId, video) {
	__.animateAlternativeListener = dom.throttle(__.animateAlternative.bind(this, moduleId, video), 100);
	window.addEventListener('scroll', __.animateAlternativeListener);
	__.setInitialAlternativeModuleData(module, moduleId, video);
};

/**
 * @description private function handling the loaded video
 * @param {DOMElement} video - the video
 * @returns {undefined}
 */
__.useVideo = function (video) {
	if (video.readyState === 4)
	{
		__.handleVideoCanPlayThrough({target: video});
	} else
	{
		video.addEventListener("canplaythrough", __.handleVideoCanPlayThrough);
		try
		{
			video.muted = true;
			video.autoplay = true;
			// needs to be set to trigger the canplaythrough eventually
			video.play();
		}
		catch (e)
		{
			console.error(e);
		}
	}
};

/**
 * @description private function returning the next siblings of the module
 * @param {DOMElement} module - the module of the video
 * @param {String} moduleId - the id of the module
 * @returns {Array} - Array of following modules
 */
__.getNextSiblings = function (module, moduleId) {
	let nextSiblings = __.getModuleData(moduleId, 'nextSiblings'),
		sibling;

	if (!!nextSiblings)
	{
		return nextSiblings;
	}
	nextSiblings = [];
	sibling = module.nextElementSibling;
	while (!!sibling)
	{
		nextSiblings.push(sibling);
		sibling = sibling.nextElementSibling;
	}
	__.setModuleData(moduleId, 'nextSiblings', nextSiblings);
	return nextSiblings;
};

/**
 * @description private function handling the top position in case there is a sticky navigation
 * @returns {undefined}
 */
__.handleTopPosition = function () {
	let videoContainer, key,
		navigation = dom.getElement(_selectorNavigation);
	_topOffset = navigation.clientHeight;

	if (_topOffset > 0)
	{
		videoContainer = dom.getElementsArray(_selectorVideoContainer);
		for (key in videoContainer)
		{
			if (videoContainer.hasOwnProperty(key))
			{
				videoContainer[key].style.top = _topOffset + 'px';
			}
		}
	}
};

/**
 * @description private function initialitzing the scroll-animation or alternative function for the Videos
 * @param {Event} event - the videoperformance event
 * @returns {undefined}
 */
__.initializeVideos = function (event) {
	let key, module, isPerformant,
		fps = 0,
		modules = dom.getElementsArray(_selectorModul),
		minfps = __.getMinimalFramesPerSecond(modules[0]);
	if (!!event.fps)
	{
		fps = event.fps;
	}
	isPerformant = fps >= minfps;

	if (isPerformant)
	{
		__.handleTopPosition();
	}
	for (key in modules)
	{
		if (modules.hasOwnProperty(key))
		{
			module = modules[key];
			__.initializeVideo(isPerformant, module);
		}
	}
};

/**
 * @description private function initialitzing a single video
 * @param {Boolean} isPerformant - whether the performance is sufficent
 * @param {DOMElement} module - the module of the video
 * @returns {undefined}
 */
__.initializeVideo = function (isPerformant, module) {
	const moduleId = module.getAttribute('id');
	const video = __.getVideoByModule(module);

	if (isPerformant)
	{
		_contentWrapper = dom.closest(module, _selectorContentWrapper);
		__.useVideo(video);
		__.setInitializeHighlightTexts(module, moduleId, video, false);
	} else
	{
		video.classList.remove('sticky');
		__.useVideoAlternative(module, moduleId, video);
		// trigger the animation once for an initial state
		__.animateAlternative(moduleId, video);
	}
};

/**
 * private listener references
 */
__.textBlockShowListener = dom.throttle(__.handleTextBlockShow, 40);
__.handleSetAllHighlightHeightListener = dom.throttle(__.handleSetAllHighlightHeight, 100);

dom.handleDocumentReady(function () {
	const Stickyfill = require('stickyfill');
	const stickyfill = Stickyfill();

	_eventBus = signal.getEmitter();
	_eventBus.on(appEvents.PAGE_LEAVE, __.removeEvents);

	let i, stickyElements = document.getElementsByClassName('sticky');

	for (i = stickyElements.length - 1; i >= 0; i--)
	{
		stickyfill.add(stickyElements[i]);
	}

	__.init();
});

export {__};
