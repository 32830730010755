/**
 *
 * basis-gallery.js is used for FEATURE-GALLERY-FULLWIDTH and MEDIATEXT-COMBINATION-SMALL
 *
 *
 */
import { dom } from 'core-utils';
import { appEvents, signal } from 'core-application';

/**
 * Object holding all private functions
 */
var __ = {};
/**
 * some selector variables
 */
__.oDefaults = {
	selectorTagVideo: 'video',
	selectorModule: '.nm-basis-gallery',
	selectorNavigationArea: '.nm-basis-gallery-navigation-area',
	selectorNavigationArrowForward: '.nm-basis-gallery-navigation-arrow-forward',
	selectorNavigationArrowBack: '.nm-basis-gallery-navigation-arrow-back',
	selectorNavigationBar: '.nm-basis-gallery-navigation-bar',
	selectorVideoPlayButton: '.nm-basis-gallery-play-button',
	selectorVideo: '.nm-basis-gallery-video',
	selectorVideoStillFrame: '.nm-basis-gallery-stillframe',
	selectorButtonOverlay: '.nm-basis-gallery-player-overlay',
	selectorMediaContainerList: '.nm-basis-gallery-slider-container-list',
	selectorMediaContainerListVideo: '.nm-basis-gallery-slider-container-list .nm-basis-gallery-video',
	selectorMediaContainerListStillFrame: '.nm-basis-gallery-slider-container-list .nm-basis-gallery-stillframe'
};

var dir = {
	LEFT: 'left',
	RIGHT: 'right'
};

var touchStartCoords = { x: -1, y: -1 };
// swipeStart
var startTime = 0;
/**
 * Object holding for all modules the current element
 */
var currentElements = {};

var _domEventDelegate;
var isDisconnected = false;
__.layerStack = [];
__.eventHandlers = new Map();

/**
 * public function initializing the module
 * @returns {undefined}
 */
function init(isLayer = false) {
	if (!isLayer) {
		const galleryModules = document.querySelectorAll(__.oDefaults.selectorModule);
		[...galleryModules].forEach(function (galleryModule) {
			__.initModule(galleryModule);
		});
	} else {
		__.layerStack.push(isLayer.element);
		const galleryModulesLayer = isLayer.element.querySelectorAll(__.oDefaults.selectorModule);
		[...galleryModulesLayer].forEach(function (galleryModuleLayer) {
			__.initModule(galleryModuleLayer);
		});
	}
}

/**
 * private function initializes the given module
 * @return nothing
 */
__.initModule = function (module) {
	isDisconnected = false;

	const moduleId = module.getAttribute('data-module-id');
	const navigationAreasList = [...module.querySelectorAll('.nm-basis-gallery-navigation-area')];
	const videoList = [...module.querySelectorAll('video')];

	if (navigationAreasList.length > 0) {
		for (var i = 0; i < navigationAreasList.length; i++) {
			currentElements[moduleId] = 0;
			var navigationArea = navigationAreasList[i];
			var previousButton = dom.getElement(__.oDefaults.selectorNavigationArrowBack, navigationArea);
			var nextButton = dom.getElement(__.oDefaults.selectorNavigationArrowForward, navigationArea);
			var navigationBar = dom.getElement(__.oDefaults.selectorNavigationBar, navigationArea);
			__.setNavigationHandlers(previousButton, nextButton, moduleId, navigationArea, navigationBar);
			__.setSwipeHandlers(previousButton, nextButton, navigationArea, navigationBar, moduleId);
			__.setVideoManualPlayHandlers(module);
			__.stopNotActiveVideoSlide(module);
			__.showsArrowsIfNecessary(moduleId, previousButton, nextButton, navigationBar);
		}
	} else {
		currentElements[moduleId] = 0;
		__.setVideoManualPlayHandlers(module);
	}

	// viewport events
	if (videoList && videoList.length > 0) {
		//set handler for autmatically video playing on scroll
		const scrollVideoListener = dom.debounce(function () {
			__.handleScrollVideo(module, videoList);
		}, 100);
		__.eventHandlers.set(module, scrollVideoListener);
		window.addEventListener('scroll', scrollVideoListener);
	}
};

/*
 * Sets the handlers relative to the slider
 */
__.setNavigationHandlers = function (previousButton, nextButton, moduleId, navigationArea, navigationBar) {
	// eslint-disable-line max-params
	previousButton.onclick = function () {
		__.moveNavigationElem(moduleId, dir.LEFT, previousButton, nextButton, navigationArea, navigationBar);
	};
	nextButton.onclick = function () {
		__.moveNavigationElem(moduleId, dir.RIGHT, previousButton, nextButton, navigationArea, navigationBar);
	};
};

/*
 * move the navigation elem in the given direction
 */
__.moveNavigationElem = function (moduleId, direction, previousButton, nextButton, navigationArea, navigationBar) {
	// eslint-disable-line max-params
	var elem = dom.getElement(__.oDefaults.selectorNavigationBar, navigationArea);
	var directionOffset = direction === dir.RIGHT ? 1 : -1;
	var currentIndex = currentElements[moduleId];
	var nextIndex = currentIndex + directionOffset;
	var newElementPosition = __.getElementPosition(nextIndex, navigationBar).left;
	var previousArrow = previousButton.getBoundingClientRect().right;
	var diff, leftElement, rightElement, offset;
	if (direction === dir.RIGHT) {
		leftElement = __.getElementPosition(currentIndex, navigationBar);
		rightElement = __.getElementPosition(currentIndex + 1, navigationBar);
		diff = rightElement.left - leftElement.left;
		offset = currentIndex === 0 && nextIndex === 1 ? 48 : 0;
	} else {
		leftElement = __.getElementPosition(currentIndex - 1, navigationBar);
		rightElement = __.getElementPosition(currentIndex, navigationBar);
		diff = -(rightElement.left - leftElement.left);
		offset = currentIndex === 1 && nextIndex === 0 ? -48 : 0;
	}
	var leftPosition = __.getElemMarginLeft(elem);
	// ensure that the element is not behind the left arrow but next to it.
	var navigationAreaWidth = navigationArea.getBoundingClientRect().width;
	var newPosition = leftPosition - diff + offset;
	elem.style.marginLeft = newPosition + 'px';
	elem.style.transition = 'margin-left .5s ease-out 0s';
	currentElements[moduleId] = nextIndex;
	// the move is animated and takes a few milliseconds.
	// We ensure that the navigations buttons update is executed the after move action by using a timeout
	setTimeout(function () {
		__.updateNavigationButtons(navigationBar, previousButton, nextButton);
	}, 510);
};

__.showArrows = function (nextButton) {
	nextButton.style.display = 'block';
};

__.isShowingArrowsNeccessary = function (navigationBar) {
	var navbarScrollWidth, navbarClientWidth, navigationItems;
	if (!navigationBar) {
		return false;
	}

	// if the navigation contains 0 or 1 item, no arrows should be shown.
	navigationItems = dom.getElementsArray('li', navigationBar);
	navigationItems = [].slice.call(navigationItems);
	if (navigationItems.length < 2) {
		return false;
	}

	navbarScrollWidth = navigationBar.scrollWidth;
	navbarClientWidth = navigationBar.clientWidth;

	if (navbarScrollWidth < navbarClientWidth) {
		return false;
	}
	if (navbarScrollWidth === navbarClientWidth) {
		return false;
	}
	if (navbarScrollWidth > navbarClientWidth) {
		return true;
	}
};

/*
 * Checks whether the arrows should be shown (e.g. only two or three navigationtabs with short text, who fit all in one line)
 */
__.showsArrowsIfNecessary = function (moduleId, previousButton, nextButton, navigationBar) {
	if (__.isShowingArrowsNeccessary(navigationBar)) {
		__.showArrows(nextButton);
	}
};

/**
 * Returns the left position of the navigation being at the given index.
 */
__.getElementPosition = function (elementIndex, navigationBar) {
	//var liElements = document.querySelectorAll('#nm-mediatext-combination-navigation-bar-' + componentId + ' li');
	var liElements = dom.getElementsArray('li', navigationBar);
	liElements = [].slice.call(liElements);
	var element = liElements[elementIndex];
	return element.getBoundingClientRect();
};

/*
 * Returns the margin left of the given elem
 */
__.getElemMarginLeft = function (elem) {
	var marginLeft = elem.style.marginLeft.replace('px', '');
	marginLeft = marginLeft === '' ? 0 : parseFloat(marginLeft);
	return marginLeft;
};

/*
 * hide or show the next and previous navigation buttons according to the current position
 * of the navigation elem within its parent
 */
__.updateNavigationButtons = function (navigationBar, previousButton, nextButton) {
	var showNextButton = __.isMoveRightPossible(navigationBar);
	var showPreviousButton = __.isMoveLeftPossible(navigationBar);
	previousButton.style.display = showPreviousButton ? 'block' : 'none';
	nextButton.style.display = showNextButton ? 'block' : 'none';
};

/*
 * Checks whether a move to the right is possible (e.g. if we haven't reached the end of the list)
 */
__.isMoveRightPossible = function (navigationBar) {
	var lastElem = dom.getElement('li:last-child', navigationBar);
	/*if the right border of the last elem is bigger than the right border of its parent
	it means that we can go further right, so we show the next button */
	var elemRectRight = lastElem.getBoundingClientRect().right;
	var elemParentRectRight = navigationBar.getBoundingClientRect().right;
	return elemRectRight > elemParentRectRight;
};
/*
 * Checks whether a move to the left is possible (e.g. if we haven't reached the beginning of the list)
 */
__.isMoveLeftPossible = function (navigationBar) {
	/* if the left border of the first elem is smaller than the left border of its parent
	it means that we can go further left, so we show the previous button */
	var firstElem = dom.getElement('li:first-child', navigationBar);
	var elemRectLeft = firstElem.getBoundingClientRect().left;

	// -
	// -
	// -
	// TODO: needs to be optimized
	// -
	// -
	// -
	var elemParentRectLeft = firstElem.parentNode.parentNode.getBoundingClientRect().left;
	return elemRectLeft < elemParentRectLeft;
};

/*
 * Sets x and y coordination values and sets startTime when the swipe event starts
 */
__.swipeStart = function (event) {
	var swipeEvent = event ? event : window.event;
	swipeEvent = 'changedTouches' in swipeEvent ? swipeEvent.changedTouches[0] : swipeEvent;
	if (swipeEvent) {
		touchStartCoords = {
			x: swipeEvent.pageX,
			y: swipeEvent.pageY
		};
		startTime = new Date().getTime();
	}
};
/*
 * Prevents standard click-behaviour
 */
__.swipeMove = function (event) {
	var swipeEvent = event ? event : window.event;
	swipeEvent.preventDefault();
};
/*
 * Moves the internalElment if the swipe-conditions are fullfilled
 */
__.swipeEnd = function (event, moduleId, previousButton, nextButton, navigationArea, navigationBar) {
	// eslint-disable-line max-params
	var diffTouchCoords = {};
	var usedTime = 0;
	var allowedMinDistanceX = 25;
	var allowedMinDistanceY = 25;
	var allowedMaxSwipeTime = 1000;
	var swipeEvent = event ? event : window.event;
	swipeEvent = 'changedTouches' in swipeEvent ? swipeEvent.changedTouches[0] : swipeEvent;
	// If there is no swipe event, we will do nothing
	if (!swipeEvent) {
		return;
	}
	// Time used/passed since the start of the swipe movement
	usedTime = new Date().getTime() - startTime;
	// If current event happens a long time after the start event, it is not a swipe so we will do nothing
	if (usedTime > allowedMaxSwipeTime) {
		return;
	}
	// Diff from start to end points of the swipe-movement
	diffTouchCoords = {
		x: swipeEvent.pageX - touchStartCoords.x,
		y: swipeEvent.pageY - touchStartCoords.y
	};
	// If the distance of the movement is too small, it is not a swipe so we will do nothing
	if (!(Math.abs(diffTouchCoords.x) >= allowedMinDistanceX && Math.abs(diffTouchCoords.y) <= allowedMinDistanceY)) {
		return;
	}
	if (diffTouchCoords.x < 0) {
		// If the x-movement is smaller than zero it is a movement to the right
		if (__.isMoveRightPossible(navigationBar)) {
			// the move is executed only if there is some element more on the right
			// if we are at the end of the list, we don't do anything
			__.moveNavigationElem(moduleId, dir.RIGHT, previousButton, nextButton, navigationArea, navigationBar);
		}
	} else {
		// Else the x-movement is bigger than zero and this means it is a movement to the left
		if (__.isMoveLeftPossible(navigationBar)) {
			// the move is executed only if there is some element more on the left
			// if we are at the beginning of the list, we don't do anything
			__.moveNavigationElem(moduleId, dir.LEFT, previousButton, nextButton, navigationArea, navigationBar);
		}
	}
};
/*
 * Sets the handlers relative to the swipe movements.
 */
__.setSwipeHandlers = function (previousButton, nextButton, navigationArea, navigationBar, moduleId) {
	// eslint-disable-line max-params
	// swipe handlers are only set if the sliders has more elements than the one shown
	// (e.g. it has some arrows to see previous/next elements)
	if (__.isShowingArrowsNeccessary(navigationBar)) {
		/*
		 * Sets to the given element an listener for the given swipeEvents
		 *
		 */
		__.addMultipleListeners = function (element, swipeEvents, listener) {
			var i;
			for (i = 0; i < swipeEvents.length; i++) {
				element.addEventListener(swipeEvents[i], listener, false);
			}
		};
		__.addMultipleListeners(navigationArea, ['mousedown', 'touchstart'], __.swipeStart);
		__.addMultipleListeners(navigationArea, ['mousemove', 'touchmove'], __.swipeMove);
		__.addMultipleListeners(navigationArea, ['mouseup', 'touchend'], function (event) {
			__.swipeEnd(event, moduleId, previousButton, nextButton, navigationArea, navigationBar);
		});
	}
};

/**
 * Checks if the given video is playable and plays it if it is
 * @param {HTMLElement} video
 * returns {undefined}
 */
__.handleVideoPlay = function (video) {
	if (isDisconnected || !video) {
		return;
	}

	/* if (video.readyState >= 3) {
		__.playVideo(video);
	}
	else {
		video.addEventListener("canplaythrough", function () {
			__.playVideo(video);
		});

		// video.load();
	} */

	var playPromise = video.play();

	if (!!playPromise) {
		playPromise.catch(function () {
			video.muted = true; // bypassing the android/ IOS restriction for autoplaying video
			video.play();
		});
	}
};

/**
 * starts the video
 * @param {HTMLElement} video
 * returns {undefined}
 */
__.playVideo = function (video) {
	if (isDisconnected) {
		video.removeEventListener('canplaythrough', function () {
			__.playVideo(video);
		});
		return;
	}

	var playPromise = video.play();

	if (!!playPromise) {
		playPromise.catch(function () {
			video.muted = true; // bypassing the android/ IOS restriction for autoplaying video
			video.play();
		});
	}
};

/* private functions */
__.setVideoManualPlayHandlers = function (module) {
	var listPlayButtons = dom.getElementsArray(__.oDefaults.selectorVideoPlayButton, module);
	listPlayButtons = [].slice.call(listPlayButtons);
	listPlayButtons.forEach(function (buttonElem) {
		var elemId = buttonElem.getAttribute('data-id');
		var videoElem = dom.getElement(__.oDefaults.selectorVideo + '-' + elemId, module);
		var stillFrameElem = dom.getElement(__.oDefaults.selectorVideoStillFrame + '-' + elemId, module);
		var buttonOverlay = dom.getElement(__.oDefaults.selectorButtonOverlay + '-' + elemId, module);

		buttonElem.addEventListener('click', function (event) {
			__.handleVideoPlay(videoElem);
			buttonElem.style.display = 'none';
			if (!!buttonOverlay) {
				buttonOverlay.style.display = 'none';
			}
			if (!!stillFrameElem) {
				stillFrameElem.style.display = 'none';
			}
			event.stopPropagation();
		});
		videoElem.onended = function () {
			buttonElem.style.display = 'block';
			if (!!buttonOverlay) {
				buttonOverlay.style.display = 'block';
			}
			if (!!stillFrameElem) {
				stillFrameElem.style.display = 'block';
			}
		};
		videoElem.onpause = function () {
			buttonElem.style.display = 'block';
			if (!!buttonOverlay) {
				buttonOverlay.style.display = 'block';
			}
		};
	});
};

// when clicking on another list media items, be sure to execute the following steps:
//   - stop any video which is currently running
//   - start the current video if it has the autoplay flag
__.stopNotActiveVideoSlideChangeHandler = function (module, listVideos, elemId) {
	return function () {
		var video, stillFrame;
		// check all other videos and stop them
		var otherVideos = Array.apply(null, listVideos).filter(function (el) {
			return el.getAttribute('data-id') !== elemId;
		});
		otherVideos.forEach(function (otherVideo) {
			otherVideo.pause();
			if (otherVideo.currentTime !== 0) {
				otherVideo.currentTime = 0;
			}
			otherVideo.setAttribute('data-active', false);
		});
		// if the current media item is video, and if it has the autoplay flag,
		// the video should be started now.
		var currentVideo = Array.apply(null, listVideos).filter(function (el) {
			return el.getAttribute('data-id') === elemId;
		});
		if (currentVideo && currentVideo.length !== 0) {
			video = dom.getElement(__.oDefaults.selectorMediaContainerListVideo + '-' + elemId, module);
			stillFrame = dom.getElement(__.oDefaults.selectorMediaContainerListStillFrame + '-' + elemId, module);
			video.setAttribute('data-active', true);
			if (video && video.getAttribute('data-autoplay')) {
				if (!!stillFrame) {
					stillFrame.style.display = 'none';
				}
				__.handleVideoPlay(video);
			} else {
				if (!!stillFrame) {
					stillFrame.style.display = 'block';
				}
			}
		}
	};
};

__.stopNotActiveVideoSlide = function (module) {
	var listMediaItems = dom.getElementsArray(__.oDefaults.selectorMediaContainerList + ' li', module);
	var listVideos = dom.getElementsArray(__.oDefaults.selectorMediaContainerList + ' video', module);
	listVideos = [].slice.call(listVideos);
	listMediaItems = [].slice.call(listMediaItems);
	listMediaItems.forEach(function (elem) {
		var elemId = elem.getAttribute('data-id');
		var checkbox = dom.getElement('.nm-basis-gallery-input[data-input-id="' + elemId + '"]', module);
		checkbox.addEventListener('change', __.stopNotActiveVideoSlideChangeHandler(module, listVideos, elemId));
	});
};

__.handleScrollVideo = function (module, videos) {
	var stillFrame, elemId;
	// get the video of the module which is currently active
	var activeVideo = videos.filter(function (video) {
		return video.getAttribute('data-active') === 'true';
	})[0];

	if (!activeVideo) {
		// there may be no active video. for example, if only pictures are shown.
		// in this case, we don't need to do anything
		return;
	}

	var videoVisibility = dom.getViewportPercentageCovered(activeVideo);
	// var videoVisibility = dom.getVisibleVerticalPercentageInViewport(activeVideo);

	elemId = activeVideo.getAttribute('data-id');
	stillFrame = dom.getElement(__.oDefaults.selectorMediaContainerListStillFrame + '-' + elemId, module);

	// if the video of the module covers less than 50% of the viewport, it should be paused
	if (videoVisibility < 50) {
		activeVideo.pause();
		// autoplay videos should be reset to time=0
		if (activeVideo.getAttribute('data-autoplay')) {
			if (activeVideo.currentTime !== 0) {
				activeVideo.currentTime = 0;
			}
		}
		if (!!stillFrame) {
			stillFrame.style.display = 'block';
		}
		// if the video of the module covers more than 50% of the viewport, it should be played
	} else {
		// the video is restarted automatically only if it's an autoplay video
		if (activeVideo.getAttribute('data-autoplay') && activeVideo.currentTime === 0) {
			if (!!stillFrame) {
				stillFrame.style.display = 'none';
			}
			__.handleVideoPlay(activeVideo);
		}
	}
};

__.removeEvents = function (e, eventType) {
	// if we deal with a layer we need to remove the scrolling handler from that specific layer
	const layerElement = (__.layerStack && __.layerStack.pop()) ?? (e && e.element);

	if (layerElement) {
		const galleryModulesLayer = layerElement.querySelectorAll(__.oDefaults.selectorModule);
		[...galleryModulesLayer].forEach(function (module) {
			const scrollVideoListener = __.eventHandlers.get(module);
			if (scrollVideoListener) {
				window.removeEventListener('scroll', scrollVideoListener);
				__.eventHandlers.delete(galleryModule);
			}
		});
	} else if (eventType == 'page.leave') {
		// having a page.leave event without any active layers on the stack we remove the scroll event handler from the global window object
		const galleryModules = document.querySelectorAll(__.oDefaults.selectorModule);
		[...galleryModules].forEach(function (galleryModule) {
			const scrollVideoListener = __.eventHandlers.get(galleryModule);
			if (scrollVideoListener) {
				window.removeEventListener('scroll', scrollVideoListener);
				__.eventHandlers.delete(galleryModule);
			}
		});

		isDisconnected = true;
	}
};
__.removeEventsPageLeave = function (e) {
	__.removeEvents(e, 'page.leave');
};

__.removeEventsLayerClosed = function (e) {
	__.removeEvents(e, 'layer.closed');
};

dom.handleDocumentReady(function () {
	var globalEventBus = signal.getEmitter();
	globalEventBus.on(appEvents.PAGE_READY, init);
	globalEventBus.on(appEvents.PAGE_LEAVE, __.removeEventsPageLeave);
	globalEventBus.on(appEvents.LAYER_LOADED, init);
	globalEventBus.on(appEvents.LAYER_CLOSED, __.removeEventsLayerClosed);
	_domEventDelegate = dom.getEventDelegate('body');
	init();
});

export { __, init }; // unit-test relevant
