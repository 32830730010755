import { dom } from 'core-utils';
import { appEvents, signal, content } from 'core-application';
/**
 * Object holding all private functions
 */

/**
 * for each module we save a reference to its dropdown and promise
 * this is necessary because on drawer module can have a dropdown which includes several layers/content
 */
var __ = {
	promises: {},
	dropdowns: {},
	modules: {},
	initializedModules: []
};
__.oDefaults = {
	pageTitle: '#nm-page-title',
	layerTitle: '.nm-layer-title',
	moduleDrawerSelector: '.nm-module-drawer',
	drawerButtonSelector: '.nm-drawer-button',
	buttonTextSelector: '.nm-drawer-text',
	headerSelector: '.nm-drawer-header',
	bodySelector: '.nm-drawer-body',
	contentSelector: '.nm-drawer-content'
};

/** global variable, so we dont have to create a new instance each time we use the eventDelegate
* this variable is initalized in the function __.initialize()
*/
var _domEventDelegate;

/**
 * private function initDropdown
 * @param {string} moduleId_ - the module id
 * @returns {undefined}
 */
__.initDropdown = function(moduleId_) {
	const dropdown = document.querySelector('#nm-drawer-' + moduleId_ + '-dropdown');

	function drowpdownCallbackFunction(data_) {
		__.onSelectionChanged(moduleId_, data_.id);
	}

	if (dropdown) {
		dropdown.subscribeToFlyout(drowpdownCallbackFunction);
	}
};

/**
 * private function onSelectionChanged
 * @param {string} moduleId_ - the module id
 * @param {string} contentId_ - ID of the selected Content
 * @returns {undefined}
 */
__.onSelectionChanged = async function (moduleId_, contentId_) {
	const contentDiv = document.querySelector(__.oDefaults.contentSelector + '[data-content-id="' + contentId_ + '"]');
	const url = contentDiv.getAttribute("data-content-url");

	try {
		const templateContent = await __.loadContentTemplateContent(url);
		__.handleDisplayBehaviourAfterContentLoaded(templateContent, contentDiv, moduleId_, contentId_);
	}
	catch(error) {
		console.log('Template could not be loaded', error);
	}
};

__.handleDisplayBehaviourAfterContentLoaded = function (templateContent_, contentDiv_, moduleId_, contentId_) {
	const drawerBody = document.querySelector(__.oDefaults.moduleDrawerSelector + "[data-module-id='" + moduleId_ + "'] " + __.oDefaults.bodySelector);
	const modelContentSelector = __.oDefaults.contentSelector + "[data-content-id='" + contentId_ + "']";
	const modelContent = document.querySelector(modelContentSelector);

	__.setTemplateInDom(contentDiv_, templateContent_);
	__.hideCurrentModelContent(moduleId_);
	__.showModelContent(modelContent);
	__.calculateAndSetBodyMaxHeight(drawerBody);
	__.dropdowns[moduleId_].currentModelId = contentId_;
	__.handleExpandableComponent(modelContent);
}

/**
 * private function handleExpandableComponent
 * @param {Element} modelContent_ - content element of current selection
 * @returns {void}
 */
__.handleExpandableComponent = function (modelContent_) {
	let expandable = undefined;

	if (modelContent_) {
		expandable = modelContent_.querySelectorAll('audi-expandable');
	}

	if (!expandable) {
		return;
	}

	expandable.forEach( component => {
		const script = {
			original: {
				basic: component.nextElementSibling,
				legacy: component.nextElementSibling.nextElementSibling,
			},
			clone: {
				basic: undefined,
				legacy: undefined
			}
		}
		script.clone.basic = document.createElement('script');
		script.clone.basic.type = "module";
		script.clone.basic.classList.add('clone');
		script.clone.basic.src = script.original.basic.getAttribute('src');
		script.clone.basic.setAttribute("defer", "");
		script.clone.legacy = document.createElement('script');
		script.clone.legacy.setAttribute("nomudule", "");
		script.clone.legacy.classList.add('clone');
		script.clone.legacy.src = script.original.legacy.getAttribute('src');
		script.clone.legacy.setAttribute("defer", "");
		script.original.basic.remove();
		script.original.legacy.remove();
		component.parentNode.insertBefore(script.clone.legacy, component.nextSibling);
		component.parentNode.insertBefore(script.clone.basic, component.nextSibling);
	});
}

/**
 * private function showModelContent
 * @param {Element} modelContent_ - content element of current selection
 * @returns {void}
 */
__.showModelContent = function (modelContent_) {
	modelContent_.classList.add("nm-drawer-content--is-visible");
};

/**
 * private function hideCurrentModelContent
 * @param {string} moduleId_ - the module id
 * @returns {undefined}
 */
__.hideCurrentModelContent = function (moduleId_ = undefined) {
	let selector;
	if (moduleId_) {
		selector =
			__.oDefaults.moduleDrawerSelector +
			"[data-module-id='" +
			moduleId_ +
			"'] .nm-drawer-content.nm-drawer-content--is-visible";
	}
	else {
		selector = __.oDefaults.contentSelector + ".nm-drawer-content--is-visible";
	}

	const modelContent = document.querySelectorAll(selector);

	if (modelContent) {
		modelContent.forEach(element => {
			element.classList.remove("nm-drawer-content--is-visible");
		});
	}
};
/**************/
/*DROPDOWN END*/
/**************/

__.setTemplateInDom = function (container_, template_) {
	var layerTitleElement;
	var globalEventBus = signal.getEmitter();

	// search for current page title to set it into the layer title
	var pageTitleElement = dom.getElement(__.oDefaults.pageTitle);
	var pageTitle = !!pageTitleElement ? pageTitleElement.innerHTML : undefined;

	container_.innerHTML = template_;

	// if there is a page title use it for the layer title
	if (pageTitle) {
		// search for the layer title element to change its value
		layerTitleElement = dom.getElement(__.oDefaults.layerTitle, container_);

		if (!!layerTitleElement) {
			layerTitleElement.innerHTML = pageTitle;
		}
	}

	globalEventBus.emit(appEvents.LAYER_LOADED, { element: container_ });
	__.dispatchLayerLoadedEvent();
};

__.dispatchLayerLoadedEvent = function(){
	try {
		let customEvent = new CustomEvent('LAYER_LOADED');

		document.dispatchEvent(customEvent);
	}
	catch (e) {
		console.error('dispatchLayerLoadedEvent: ', 'the custom-event for LAYER_LOADED could not be dispatched', e);
	}
};

__.handleContentWrapper = function () {
	return function (body) {
		var contentDivs = dom.getElementsArray(__.oDefaults.contentSelector, body);
		var preloadArr = [];
		contentDivs.forEach(function (contentDiv) {
			var url = contentDiv.getAttribute("data-content-url");
			preloadArr.push(url);
		});
		content.fillCache(preloadArr);
	};
};

__.loadContentTemplateContent = function (url_) {
	return new Promise(function (resolve, reject) {
		content.getContent(url_, false).then(function (content) {
			resolve(content);
		}).catch(function (error) {
			console.warn("content url content: ", url_, error);
			reject(error);
		});
	});
};

__.handleButtonClick = function (context_) {
	var isDrawerOpen = context_.classList.contains('nm-open');
	if (isDrawerOpen) {
		context_.classList.remove('nm-open');
		__.closeDrawer(context_);
	} else {
		context_.classList.add('nm-open');
		__.openDrawer(context_);
	}
};

__.openDrawer = async function (context_) {
	var firstContentDiv, body, header, scrollToPosition, moduleId, url;
	scrollToPosition = window.pageYOffset;

	moduleId = context_.getAttribute('data-module-id');
	__.modules[moduleId].scrollToPosition = scrollToPosition;

	//get the matching module to the clicked Button
	body = dom.getElement(__.oDefaults.bodySelector, context_);
	header = dom.getElement(__.oDefaults.headerSelector, context_);
	firstContentDiv = dom.getElement(__.oDefaults.contentSelector, body);

	url = firstContentDiv.getAttribute("data-content-url");

	try {
		const templateContent = await __.loadContentTemplateContent(url);
		__.setTemplateInDom(firstContentDiv, templateContent);
		__.calculateAndSetBodyMaxHeight(body);

		// get the first model-id and set it as current modelid for the given module
		var firstModelId = firstContentDiv.getAttribute("data-content-id");
		__.dropdowns[moduleId].currentModelId = firstModelId;

		var moduleTheme = context_.getAttribute('data-theme');
		body.style.backgroundColor = moduleTheme === 'light' ? '#b3b3b3' : '#4c4c4c';

		// scroll listener should be set only when the module is opened to avoid unnecessary calls
		var btnSelector = __.oDefaults.moduleDrawerSelector + "[data-module-id='" + moduleId + "'] " + __.oDefaults.drawerButtonSelector;
		var scrollListener = __.scrollListener(context_, body, header, btnSelector);
		__.modules[moduleId].scrollListener = scrollListener; // save listener reference for the remove listener call
		window.addEventListener("scroll", scrollListener);

		// resize listener should be set only when the module is opened to avoid unnecessary calls
		var resizeListener = dom.debounce(__.resizeListener(context_, body), 200);
		__.modules[moduleId].resizeListener = resizeListener; // save listener reference for the remove listener call
		window.addEventListener("resize", resizeListener);
	}
	catch (err) {
		console.warn("drawer open err: " + err);
	}
};

__.closeDrawer = function (context_) {
	var moduleId = context_.getAttribute('data-module-id');
	var closeBtn = dom.getElement(__.oDefaults.drawerButtonSelector, context_);
	var body = dom.getElement(__.oDefaults.bodySelector, context_);

	// content is not shown any more
	body.style.maxHeight = 0;

	window.scrollTo(0, __.modules[moduleId].scrollToPosition);
	closeBtn.classList.remove('nm-fixed-button');

	// remove listeners added when opening the module
	window.removeEventListener("scroll", __.modules[moduleId].scrollListener);
	window.removeEventListener("resize", __.modules[moduleId].resizeListener);

	// timeout is needed to execute the code after animation has been done
	setTimeout(function () {
		var initColor = context_.getAttribute('data-init-color');
		context_.style.backgroundColor = initColor;
	}, 500);
};

__.onContentScroll = function (closeButton, startFixingButton, module) {
	var scrollTop, moduleBottomPosition;
	if (module.classList.contains('nm-open')) {
		scrollTop = document.body.scrollTop || window.pageYOffset || window.scrollY;
		// position of the module bottom in the viewport
		moduleBottomPosition = module.getBoundingClientRect().bottom;

		if (scrollTop > startFixingButton && moduleBottomPosition > 140) {
			closeButton.classList.add('nm-fixed-button');
			closeButton.classList.remove('nm-absolute-button');
		} else {
			closeButton.classList.add('nm-absolute-button');
			closeButton.classList.remove('nm-fixed-button');
		}
	}
};

__.calculateAndSetBodyMaxHeight = function (body) {
	var heightBuffer = 1500;
	var bodyMaxHeight = body.scrollHeight + heightBuffer;

	body.style.maxHeight = bodyMaxHeight + 'px';
};

__.scrollListener = function (context, body, header, btnSelector) {
	var button = dom.getElement(btnSelector);
	return function () {
		var headerBottomPosition = header.getBoundingClientRect().top;
		var topPage = document.getElementsByTagName('body')[0].getBoundingClientRect().top;
		headerBottomPosition = -1 * (topPage - headerBottomPosition);
		__.onContentScroll(button, headerBottomPosition, context);
	};
};

__.resizeListener = function (module, body) {
	return function () {
		if (module.classList.contains('nm-open')) {
			__.calculateAndSetBodyMaxHeight(body);
		}
	};
};

/*
 * private function adding events-handlers
 */
__.addEvents = function (context) {
	//handle open clicks
	var moduleId = context.getAttribute('data-module-id');
	var btnSelector = __.oDefaults.moduleDrawerSelector + "[data-module-id='" + moduleId + "'] " + __.oDefaults.drawerButtonSelector;
	_domEventDelegate.on("click", btnSelector, function () { __.handleButtonClick(context); });
};

__.initContent = function (module) {
	var bodys = dom.getElementsArray(__.oDefaults.bodySelector, module);
	bodys.forEach(__.handleContentWrapper());
};

/*
 * public initialize method for all existing module instances
 * @returns
 */
__.initialize = function () {
	var i, module, moduleId;
	var moduleList = document.querySelectorAll(__.oDefaults.moduleDrawerSelector);

	for (i = 0; i < moduleList.length; i++) {
		module = moduleList[i];

		if (module) {
			moduleId = dom.getDataAttribute(module, 'data-module-id');

			// if module has already been initialized than don't initialize it a second time
			// to avoid event-handlers to be set multiple times.
			if (__.initializedModules.indexOf(moduleId) === -1) {
				__.dropdowns[moduleId] = {};

				__.initDropdown(moduleId);

				// marks the module as initialized
				__.initializedModules.push(moduleId);
				__.modules[moduleId] = {};
			}
			__.addEvents(module);

			// initContent needs to wait for APP_INITIALIZED to be able to load the data
			// thats why it may be called multiple times.
			__.initContent(module);
		}
	}
};

/*
 * private init method
 * @returns {void}
 */
function init() {
	var drawer = dom.getElement(__.oDefaults.moduleDrawerSelector);
	if (dom.isElement(drawer)) {
		__.initialize();
	}
}

dom.handleDocumentReady(function () {
	_domEventDelegate = dom.getEventDelegate('body');
	var globalEventBus = signal.getEmitter();

	globalEventBus.on(appEvents.PAGE_READY, init);
	init();
});

export {
	__, init
};
