import {dom, fetch} from 'core-utils';
import {appEvents, signal} from 'core-application';

/**
 * Object holding all private functions
 */
let __ = {};

/**
 * some selector variables
 */
const _selectorModule = '.nm-module-scroll-highlight',
	_selectorVideo = '.nm-scroll-highlight-video-player',
	_selectorHighlightText1 = '.nm-scroll-highlight-highlighttext-one',
	_selectorHighlightText2 = '.nm-scroll-highlight-highlighttext-two',
	_selectorTextBlockHidden = '.nm-scroll-highlight-textblock.nm-scroll-highlight-hidden',
	_selectorPageWrapper = '.nm-wrapper',
	_selectorHighlightTextWrapper = '.nm-scroll-highlight-textoverlay-wrapper';

/**
 * some module variables
 */
let _moduleData,
	_eventBus,
	_isExtraWideScreen,
	_hiddenTextBlocks = null;

/**
 * public initialize method
 */
function init() {
	const modules = dom.getElementsArray(_selectorModule);

	if (dom.isElement(modules[0])) {
		__.handleTextBlockShow();
		__.handleSetupHighlightTexts(false);
		__.addEvents();

		if (__.isExtraWideScreen()) { // alternative wide-screen
			__.initializeAlternativeVideos(modules);
		} else {
			_eventBus.addListener('video_performance', __.initializePerVideoPerformance);
		}
	}
}

/**
 * private function handling the visibility of the textblocks
 */
__.handleTextBlockShow = function() {
	const textBlocks =  __.getHiddenTextBlocks();

	if (textBlocks.length < 1) {
		window.removeEventListener("scroll", __.textBlockShowListener);
	} else {
		for(const key in textBlocks) {
			if (!textBlocks.hasOwnProperty(key)) {
				continue;
			}
			if (dom.getVisibleVerticalPercentageInViewport(textBlocks[key]) >= 25) {
				textBlocks[key].classList.remove("nm-scroll-highlight-hidden");
			}
		}
		_hiddenTextBlocks = dom.getElementsArray(_selectorTextBlockHidden);
	}
};

/**
 * private function get all number elements still hidden
 * @returns {Array} - hidden textblocks
 */
__.getHiddenTextBlocks = function() {
	if (!_hiddenTextBlocks) {
		_hiddenTextBlocks = dom.getElementsArray(_selectorTextBlockHidden);
	}
	return _hiddenTextBlocks;
};

/**
 * private function setting the highlight texts up
 * @param {Boolean} onResize - whether the function was called while resize or not
 */
__.handleSetupHighlightTexts = function(onResize) {
	const modules = dom.getElementsArray(_selectorModule);

	for(const key in modules) {
		if(modules.hasOwnProperty(key)){
			const module = modules[key];
			const moduleId = module.getAttribute('id');

			if (!onResize) {
				const video = __.getVideoByModule(module);
				const posViewPort = __.getModuleData(moduleId, 'startPosition') - video.getBoundingClientRect().top;

				__.setInitialHighlightData(module, moduleId, video);
				__.setTextOpacity(moduleId, posViewPort);
			}

			__.setHighlightHeight(moduleId);
		}
	}
};

/**
 * private function get the video of the module
 * @param {Element} module - the module
 * @returns {Element} video
 */
__.getVideoByModule = function(module) {
	return dom.getElement(_selectorVideo, module);
};

/**
 * private function set the initial Data for the highlight texts
 * @param {Element} module - the module of the video
 * @param {string} moduleId - the id of the module
 * @param {Element} video - the video
 */
__.setInitialHighlightData = function(module, moduleId, video) {
	__.setModuleData(moduleId, 'startPosition', __.getStartPositionDesignHighlights(video));
	__.setModuleData(moduleId, 'text1', dom.getElement(_selectorHighlightText1, module));
	__.setModuleData(moduleId, 'text2', dom.getElement(_selectorHighlightText2, module));
	__.setModuleData(moduleId, 'textOverlayStartPosition', __.getModuleData(moduleId, 'startPosition') * 0.45);
	__.setModuleData(moduleId, 'textOverlayEndPosition', __.getModuleData(moduleId, 'startPosition') * 0.55);
	__.setModuleData(moduleId, 'textOverlayPixelMapping', 1 / (__.getModuleData(moduleId, 'textOverlayEndPosition') -  __.getModuleData(moduleId, 'textOverlayStartPosition')));
};

/**
 * private function set data of the module
 * @param {string} moduleId - the id of the module
 * @param {string} key - the data key
 * @param {string | number} value - the data value
 */
__.setModuleData = function(moduleId, key, value) {
	if (!_moduleData) {
		_moduleData = {};
	}
	if (!_moduleData[moduleId]) {
		_moduleData[moduleId] = {};
	}
	_moduleData[moduleId][key] = value;
};

/**
 * private function get data of the module
 * @param {String} moduleId - the id of the module
 * @param {String} key - the data key
 * @returns {null|object|string|number} - value
 */
__.getModuleData = function(moduleId, key) {
	if (!_moduleData || !_moduleData[moduleId] || !_moduleData[moduleId][key]) {
		return null;
	}
	return _moduleData[moduleId][key];
};

/**
 * private function setting the height of the highlight-wrapper
 * @param {String} moduleId - the id of the module
 */
__.setHighlightHeight = function(moduleId) {
	let highlightWrapper;
	const firstHighlight = __.getModuleData(moduleId, 'text1'),
		secondHighlight = __.getModuleData(moduleId, 'text2');

	if (dom.isElement(firstHighlight)) {
		highlightWrapper = dom.closest(firstHighlight, _selectorHighlightTextWrapper);
		highlightWrapper.style.minHeight = "0px";
	}

	if (dom.isElement(secondHighlight) && window.innerWidth < 768) {
		highlightWrapper.style.minHeight = secondHighlight.clientHeight + "px";
	}
};

/**
 * private listener references
 */
__.textBlockShowListener = dom.throttle(__.handleTextBlockShow, 40);
__.setupHighlightTextsListener = dom.throttle(__.handleSetupHighlightTexts.bind(this, true), 100);
__.isExtraWideScreenListener = dom.debounce(function() {_isExtraWideScreen = undefined;}, 100);

/**
 * private function adding events
 */
__.addEvents = function() {
	window.addEventListener('scroll', __.textBlockShowListener);
	window.addEventListener('resize', __.setupHighlightTextsListener);
	window.addEventListener('resize', __.isExtraWideScreenListener);
};

/**
 * private function removing events
 */
__.removeEvents = function () {
	window.removeEventListener('scroll', __.textBlockShowListener);
	window.removeEventListener('resize', __.setupHighlightTextsListener);
	window.removeEventListener('resize', __.isExtraWideScreenListener);

	if (__.animateModuleAlternativeListener) {
		window.removeEventListener('scroll', __.animateModuleAlternativeListener);
	}

	if (__.animateModuleListener) {
		window.removeEventListener('scroll', __.animateModuleListener);
	}

	_eventBus.off(appEvents.PAGE_LEAVE, __.removeEvents);
};

/**
 * private function checking whether the viewport aspect-ratio is bigger then 16:9
 * @returns {Boolean} whether the viewport aspect-ratio is bigger then 16:9
 */
__.isExtraWideScreen = function () {
	let pageWrapper;

	if (!!_isExtraWideScreen) {
		return _isExtraWideScreen;
	}

	pageWrapper = dom.getElement(_selectorPageWrapper);
	return (pageWrapper.clientWidth / window.innerHeight) > (16/9);
};

/**
 * private function initializing the scroll-animation video alternative
 * @param {Array} modules - list of all modules on the page
 */
__.initializeAlternativeVideos = function (modules) {
	for(const key in modules) {
		if(modules.hasOwnProperty(key)){
			const module = modules[key];
			const video = __.getVideoByModule(module);
			__.loadVideo(video);
			// trigger the animation once for an initial state
			__.animateModuleAlternative(video);
		}
	}
};

/**
 * private function handling the request to get the video
 * @param {String} url - the url for the request
 * @param {Element} module - the current module
 * @param {function} callback - the function to be executed after the loading
 */
__.ajaxLoadVideo = function(url, module, callback) {
	fetch.getBlob(url, {
		method: 'get'
	}).then(function(blob){
		const objectURL = URL.createObjectURL(blob);
		callback(module, objectURL);
	}).catch(function(err) {
		console.warn("Error:" + err.message);
	});
};

/**
 * private function get the moduleId by some child element
 * @param {Element} element - child element of the module
 * @returns {string | null} - moduleId
 */
__.getModuleIdByElement = function(element) {
	const module = dom.closest(element, _selectorModule);

	if (dom.isElement(module) === false) {
		return null;
	}

	return module.getAttribute('id');
};

/**
 * private function returning the value for the minimal fps
 * @param {Element} module - one scroll technology module
 * @returns {number} minimal frames per second
 */
__.getMinimalFramesPerSecond = function(module) {
	const minFps = dom.getDataAttribute(module, 'minfps');

	if (!!minFps && minFps > 0) {
		return minFps;
	}

	return 15;
};

/**
 * private function get the mapping
 * @param {HTMLVideoElement} video - the video
 * @returns {number} mapping
 */
__.getPixelDurationMapping = function(video) {
	return video.duration / __.getStartPositionDesignHighlights(video);
};

/**
 * private function get the start position of the video
 * @param {Element} video - the video
 * @returns {number} start position
 */
__.getStartPositionDesignHighlights = function(video) {
	return window.innerHeight - (video.clientHeight * 0.75);
};

/**
 * returns the current position of the video in view
 * pos = -1  -> video-top above viewport-top or below start position
 * @param {string} moduleId - the id of the module
 * @param {Element} video - the video
 * @returns {number} current position of video
 */
__.getCurrentPositionInView = function(moduleId, video) {
	if (!video) {
		return;
	}

	const rect = video.getBoundingClientRect(),
		startPos = __.getModuleData(moduleId, 'startPosition');

	if (rect.top >= 0 && startPos >= rect.top) {
		return startPos - rect.top;
	}

	return -1;
};

/**
 * private function handling the video if it is ready to be played
 * @param {Event} event - the canPlayThroughEvent
 */
__.handleVideoCanPlayThrough = function(event) {
	const video = event.target,
		moduleId = __.getModuleIdByElement(video);

	video.removeEventListener("canplaythrough", __.handleVideoCanPlayThrough);
	video.pause();
	video.currentTime = 0;
	video.muted = false;

	__.setModuleData(moduleId, 'pixelDurationMapping', __.getPixelDurationMapping(video));
	__.animateModuleListener = dom.throttle(__.animateModule.bind(this, moduleId, video), 40);
	window.addEventListener('scroll', __.animateModuleListener);
};

/**
 * private function handling the loaded video
 * @param {Element} module - the HTML module of the video
 * @param {string} videoSrc - the src of the video
 */
__.handleLoadedVideo = function(module, videoSrc) {
	const video = __.getVideoByModule(module);

	video.src = videoSrc;
	video.addEventListener("canplaythrough", __.handleVideoCanPlayThrough);
	video.muted = true;

	// needs to be set to trigger the canplaythrough eventually
	video.play();
};
/**
 * private function handle the animation of the module
 * @param {String} moduleId - the id of the module
 * @param {Element} video - the video
 */
__.animateModule = function(moduleId, video) {
	if (!video) {
		return;
	}

	const posViewPort = __.getCurrentPositionInView(moduleId, video);

	if (posViewPort >= 0) {
		__.animateVideo(moduleId, video, posViewPort);
		__.setTextOpacity(moduleId, posViewPort);
	}
};

/**
 * private function handle the animation of the videoSrc
 * @param {String} moduleId - the id of the module
 * @param {Element} video - the video of the module
 * @param {number} posViewPort - the position in the viewport
 */
__.animateVideo = function(moduleId, video, posViewPort) {
	video.currentTime = __.getModuleData(moduleId, 'pixelDurationMapping') * posViewPort;
};

/**
 * private function handling the animation of the texts
 * @param {String} moduleId - the id of the module
 * @param {number} posViewPort - the ciurrent position in the viewport
 */
__.setTextOpacity = function(moduleId, posViewPort) {
	let opacity = __.calculateHighlightTextsOpacity(
		__.getModuleData(moduleId, 'textOverlayPixelMapping'),
		posViewPort,
		__.getModuleData(moduleId, 'textOverlayStartPosition')
	);

	if (opacity > 1) {
		opacity = 1;
	} else if (opacity < 0) {
		opacity = 0;
	}
	__.getModuleData(moduleId, 'text1').style.opacity = 1 - opacity;
	__.getModuleData(moduleId, 'text2').style.opacity = opacity;
};

/**
 * @description private function calculating the opacity of the highlight texts
 * @param {Number} pixelMapping - textOverlayPixelMapping
 * @param {Number} posViewPort - position of the video in the viewport
 * @param {Number} startPosition - start position of the animation
 * @returns {Number} the calculated opacity
 */
__.calculateHighlightTextsOpacity = function(pixelMapping, posViewPort, startPosition) {
	let opacity = Math.round((pixelMapping * (posViewPort - startPosition))*10)/10;

	if (opacity > 1) {
		opacity = 1;
	} else if (opacity < 0) {
		opacity = 0;
	}
	return opacity;
};

/**
 * private function handling the autoplay alternatives
 * @param {HTMLVideoElement} video - the video
 */
__.animateModuleAlternative = function(video) {
	if (video.paused && video.currentTime === 0) {
		if (__.isExtraWideScreen() && dom.getViewportPercentageCovered(video) >= 75){ // extra wide screen
			__.startVideo(video);
		} else if(!__.isExtraWideScreen() && dom.getVisibleVerticalPercentageInViewport(video) >= 75){ // normal screen
			__.startVideo(video);
		}
	} else if (video.currentTime > 0 && dom.getVisibleVerticalPercentageInViewport(video) === 0){
		__.pauseVideo(video);
	}
};

/**
 * private function starting the autoplay
 * @param {HTMLVideoElement} video - the video of the module
 */
__.startVideo = function(video) {
	if (!video) {
		return;
	}

	const playPromise = video.play();

	if (playPromise !== undefined) {
		playPromise.catch(error => {
			console.error(error);
			video.muted = true;
			video.play();
		});
	}
};

/**
 * private function stopping the autoplay
 * @param {HTMLVideoElement} video - the video of the module
 */
__.pauseVideo = function(video) {
	video.pause();
	video.currentTime = 0;
};

/**
 * private function handling the loading of the video
 * @param {Element} video - the current module
 */
__.loadVideo = function(video) {
	video.src = __.getVideoSrc(video);
	__.animateModuleAlternativeListener = dom.throttle(__.animateModuleAlternative.bind(this, video), 100);
	window.addEventListener('scroll', __.animateModuleAlternativeListener);
};

/**
 * private function returning the source of the video based on the screen-width
 * @param {Element} video - the video
 * @returns {String} video source
 */
__.getVideoSrc = function(video) {
	let source,
		screenWidth = window.innerWidth;

	source = dom.getDataAttribute(video, 'srcxl');

	if (screenWidth < 1025) {
		source = (dom.getDataAttribute(video, 'srcl') || source);
	}
	if (screenWidth < 768) {
		source = (dom.getDataAttribute(video, 'srcm') || source);
	}
	if (screenWidth < 415) {
		source = (dom.getDataAttribute(video, 'srcs') || source);
	}
	if (!source) {
		source = (dom.getDataAttribute(video, 'srcl') || dom.getDataAttribute(video, 'srcm') || dom.getDataAttribute(video, 'srcs'));
	}
	return source;
};

/**
 * private function initializing the modules videos based on given performance
 * @param {Event} event - the video performance event
 */
__.initializePerVideoPerformance = function (event) {
	let fps = 0,
		modules = dom.getElementsArray(_selectorModule),
		minFps = __.getMinimalFramesPerSecond(modules[0]);

	if (!!event.fps) {
		fps = event.fps;
	}
	if (fps >= minFps) {
		__.initializeVideos(modules);
	} else {
		__.initializeAlternativeVideos(modules);
	}
};

/**
 * private function initializing the scroll-animation video
 * @param {Array} modules - list of all modules on the page
 */
__.initializeVideos = function(modules) {
	for(const key in modules) {
		if(modules.hasOwnProperty(key)){
			const module = modules[key];
			const moduleId = module.getAttribute('id');
			const video = __.getVideoByModule(module);

			__.loadVideoForAnimation(module, video);
			// trigger the animation once for an initial state
			__.animateModule(moduleId, video);
		}
	}
};

/**
 * private function handling the loading of the video
 * @param {Element} module - the current module
 * @param {Element} video - the current video
 */
__.loadVideoForAnimation = function(module, video) {
	const videoSrc = __.getVideoSrc(video);
	__.ajaxLoadVideo(videoSrc, module, __.handleLoadedVideo);
};

dom.handleDocumentReady(function() {
	_eventBus = signal.getEmitter();
	_eventBus.on(appEvents.PAGE_LEAVE, __.removeEvents);
	init();
});

export { __, init };
